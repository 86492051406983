import { notify } from "@kyvg/vue3-notification";
import { Store, useStore } from "vuex";
import { storeTypes } from "@/store/constants";

export async function login(
  email: string,
  password: string,
  store: Store<any>
): Promise<boolean> {
  let result = Promise.resolve(true);
  if (!email || !password) {
    notify({
      title: "Заполните поля",
      type: "error",
    });
    return Promise.resolve(false);
  }

  await store
    .dispatch(storeTypes.AUTH, { email, password })
    .catch(() => (result = Promise.resolve(false)));
  return result;
}

export function getProfile(store: Store<any>) {
  return store.dispatch(storeTypes.GET_PROFILE);
}
