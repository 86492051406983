import { createStore } from "vuex";
import { actions } from "@/store/actions";
import { mutations } from "@/store/mutations";
import { storage } from "@/store/storage";
import { State } from "@/store/interfaces";

export default createStore<State>({
  state: storage,
  getters: {},
  mutations: mutations,
  actions: actions,
  modules: {},
});
