import { commonTypes, processTypes, storeTypes } from "@/store/constants";
import { MutationTree } from "vuex";
import { Parking, Request, Session, State, User } from "@/store/interfaces";

export const mutations: MutationTree<State> = {
  [storeTypes.SET_PROFILE](state, user: User) {
    state.user = user;
  },
  [storeTypes.SET_PARKING](state, parking) {
    state.parking = parking;
  },
  [storeTypes.SET_WORKING_PLACE](state, parking: Parking) {
    state.workPlace = parking;
  },
  [storeTypes.START_SHIFT](state) {
    state.shiftStarted = true;
  },
  [storeTypes.FINISH_SHIFT](state) {
    state.shiftStarted = false;
  },
  [storeTypes.SET_PROCESS](state, process: processTypes) {
    state.currentProcess = process;
  },
  [storeTypes.SET_SESSION_TO_CREATE](state, session: Session) {
    state.sessionToCreate = session;
  },
  [storeTypes.SET_LOADING](state, value: boolean) {
    state.loading = value;
  },
  [storeTypes.SET_REQUESTS](state, requests: Request[]) {
    state.requests = requests;
  },
  [storeTypes.SET_REQUEST_IN_PROGRESS](state, request: Request) {
    state.requestInProgress = request;
  },
};
