export enum storeTypes {
  AUTH = "AUTH",
  GET_PROFILE = "GET_PROFILE",
  SET_PROFILE = "SET_PROFILE",
  GET_PARKING = "GET_PARKING",
  SET_PARKING = "SET_PARKING",
  SET_WORKING_PLACE = "SET_WORKING_PLACE",
  START_SHIFT = "START_SHIFT",
  FINISH_SHIFT = "FINISH_SHIFT",
  LOGOUT = "LOGOUT",
  SET_PROCESS = "SET_PROCESS",
  SET_SESSION_TO_CREATE = "SET_SESSION_TO_CREATE",
  CREATE_SESSION = "CREATE_SESSION",
  SET_LOADING = "SET_LOADING",
  GET_SESSION = "GET_SESSION",
  UPDATE_SESSION = "UPDATE_SESSION",
  GET_REQUESTS = "GET_REQUESTS",
  SET_REQUESTS = "SET_REQUESTS",
  SET_REQUEST_IN_PROGRESS = "SET_REQUEST_IN_PROGRESS",
  UPDATE_SESSION_STATE = "UPDATE_SESSION_STATE",
  ACCEPT_REQUEST = "ACCEPT_REQUEST",
  FINISH_REQUEST = "FINISH_REQUEST",
  GET_HISTORY_SESSIONS = "GET_HISTORY_SESSIONS",
  START_GETTINGS_REQUESTS_TIMER = "START_GETTINGS_REQUESTS_TIMER",
  UPLOAD_AVATAR = "UPLOAD_AVATAR",
  DISABLE_TELEGRAM_NOTIFICATIONS = "DISABLE_TELEGRAM_NOTIFICATIONS",
}

export enum processTypes {
  RECEIVING_A_CAR = "RECEIVING_A_CAR",
  PARKING_THE_CAR = "PARKING_THE_CAR",
  CAR_DELIVERY = "CAR_DELIVERY",
  FORCE_ACCEPT_REQUEST = "FORCE_ACCEPT_REQUEST",
  CAR_READY_TO_ISSUANCE = "CAR_READY_TO_ISSUANCE",
  CAR_IS_ISSUED = "CAR_IS_ISSUED",

  REQUESTS_LIST_OPEN = "REQUESTS_LIST_OPEN",
  HISTORY_REQUESTS_LIST_OPEN = "HISTORY_REQUESTS_LIST_OPEN",
}

export enum commonTypes {
  ACCESS_TOKEN = "ACCESS_TOKEN",
  REFRESH_TOKEN = "REFRESH_TOKEN",
  CREATED_SESSION_ID = "CREATED_SESSION_ID",
  WORKPLACE_ID = "WORKPLACE_ID",
}

export enum SessionStates {
  VALET_SESSION_RECEIVED_THE_CAR = 1, // Принял автомобиль
  VALET_SESSION_PARKING_THE_CAR = 2, // Парковка автомобиля
  VALET_SESSION_THE_CAR_IS_PARKED = 3, // Автомобиль припаркован
  VALET_SESSION_REQUESTING_A_CAR_DELIVERY = 4, // Запрошена подача автомобиля
  VALET_SESSION_IN_THE_PROCESS = 5, // В процессе подачи
  VALET_SESSION_THE_PERSON_IN_CHARGE = 6, // Назначен ответственный
  VALET_SESSION_THE_CAR_IS_WAITING = 7, // Машина ожидает
  VALET_SESSION_THE_CAR_IS_ISSUED = 8, // Машина выдана
  VALET_SESSION_COMPLETED = 9, // Валет сессия завершена
  VALET_SESSION_COMPLETED_AND_PAID = 10, // Сессия завершена и оплачена
}
