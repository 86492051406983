
import { mapActions, mapMutations, mapState } from "vuex";
import { commonTypes, storeTypes } from "@/store/constants";
import Toggle from "@vueform/toggle";
import BlueButton from "@/components/common/BlueButton/BlueButton.vue";
import { generateString } from "@/helpers/helpers";
import { Api } from "@/api/api";

export default {
  name: "UserMenu",
  data() {
    return {
      search: "",
      show: false,
      listOptions: {
        online: true,
        telegram: false,
      },
    };
  },
  created() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (this.user.telegram_id) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.listOptions.telegram = true;
    }
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    hideMenu() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.$emit("update:open", false);
    },
    logout() {
      this[storeTypes.LOGOUT]();
    },
    finishWork() {
      this[storeTypes.FINISH_SHIFT]();
      localStorage.removeItem(commonTypes.WORKPLACE_ID);
    },
    setTelegramNotifications() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (this.listOptions.telegram) {
        window.open(
          "https://t.me/ParkpassValetAppBot?start=" +
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            `${generateString(12)}${this.user.id}${generateString(24)}`,
          "_blank"
        );
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.listOptions.telegram = true;
      } else {
        Api[storeTypes.DISABLE_TELEGRAM_NOTIFICATIONS]();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.listOptions.telegram = false;
      }
    },
    ...mapActions([storeTypes.LOGOUT]),
    ...mapMutations([storeTypes.FINISH_SHIFT]),
  },
  components: {
    Toggle,
    BlueButton,
  },
};
