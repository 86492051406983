import { Api } from "@/api/api";
import { notify } from "@kyvg/vue3-notification";
import { ActionTree } from "vuex";
import {
  commonTypes,
  processTypes,
  SessionStates,
  storeTypes,
} from "@/store/constants";
import { SessionToCreate, State } from "@/store/interfaces";
import {
  deleteTokens,
  setActiveRequest,
  updateTokens,
} from "@/helpers/helpers";
import router from "@/router";

export const actions: ActionTree<State, object | null> = {
  async [storeTypes.AUTH](state, data) {
    const { email, password } = data;

    await Api[storeTypes.AUTH]({ email, password })
      .then((res) => {
        const { access_token, refresh_token } = res.data;
        updateTokens({ access_token, refresh_token });
      })
      .catch((err) => {
        console.log(err);
        notify({
          title:
            err.response && err.response.data && err.response.data?.message
              ? err.response.data.message
              : err.message,
          type: "error",
        });
        return Promise.reject(err);
      });
  },
  [storeTypes.GET_PROFILE](state) {
    return Api[storeTypes.GET_PROFILE]()
      .then(({ data }) => {
        state.commit(storeTypes.SET_PROFILE, data);
      })
      .catch((err) => {
        notify({
          title:
            err.response && err.response.data && err.response.data?.message
              ? err.response.data.message
              : err.message,
          type: "error",
        });
      });
  },
  [storeTypes.GET_PARKING](state) {
    return Api[storeTypes.GET_PARKING]()
      .then(({ data }) => {
        state.commit(storeTypes.SET_PARKING, data);
      })
      .catch((err) => {
        notify({
          title:
            err.response && err.response.data && err.response.data?.message
              ? err.response.data.message
              : err.message,
          type: "error",
        });
      });
  },
  async [storeTypes.CREATE_SESSION](state, session: SessionToCreate) {
    const formData = new FormData();

    Object.keys(session).forEach((key) => {
      if (["photo_accept"].includes(key)) {
        if (session.photo_accept.length) {
          session.photo_accept.forEach((i: any) => {
            if (i.file) {
              formData.append(key, i.file, "pic.jpeg");
            }
          });
        }
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (session[key]) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          formData.append(key, session[key]);
        }
      }
    });

    await Api[storeTypes.CREATE_SESSION](formData)
      .then(({ data }) => {
        state.commit(storeTypes.SET_SESSION_TO_CREATE, data);
        localStorage.setItem(commonTypes.CREATED_SESSION_ID, data.id);
        return Promise.resolve();
      })
      .catch((err) => {
        console.log(err);

        notify({
          title:
            err.response && err.response.data && err.response.data?.message
              ? err.response.data.message
              : err.message,
          type: "error",
        });
        return Promise.reject();
      });
  },
  async [storeTypes.UPDATE_SESSION](state, session: SessionToCreate) {
    const formData = new FormData();

    Object.keys(session).forEach((key) => {
      if (["photo_parking"].includes(key)) {
        if (session.photo_parking.length) {
          session.photo_parking.forEach((i: any) => {
            if (i.file) {
              formData.append(key, i.file, "pic.jpeg");
            }
          });
        }
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (session[key]) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          formData.append(key, session[key]);
        }
      }
    });

    await Api[storeTypes.UPDATE_SESSION](formData)
      .then(({ data }) => {
        state.commit(storeTypes.SET_SESSION_TO_CREATE, data);
        localStorage.setItem(commonTypes.CREATED_SESSION_ID, data.id);
        return Promise.resolve();
      })
      .catch((err) => {
        console.log(err);
        notify({
          title:
            err.response && err.response.data && err.response.data?.message
              ? err.response.data.message
              : err.message,
          type: "error",
        });
        return Promise.reject();
      });
  },
  async [storeTypes.UPDATE_SESSION_STATE](
    state,
    {
      status,
      id,
      responsible_for_delivery_id,
    }: {
      status: SessionStates;
      id: number;
      responsible_for_delivery_id: number;
    }
  ) {
    const formData = new FormData();
    formData.append("state", String(status));
    formData.append("id", String(id));
    if (responsible_for_delivery_id) {
      formData.append(
        "responsible_for_delivery_id",
        String(responsible_for_delivery_id)
      );
    }

    await Api[storeTypes.UPDATE_SESSION](formData)
      .then(() => {
        return Promise.resolve();
      })
      .catch((err) => {
        notify({
          title:
            err.response && err.response.data && err.response.data?.message
              ? err.response.data.message
              : err.message,
          type: "error",
        });
        return Promise.reject();
      });
  },
  async [storeTypes.GET_SESSION](state, id: string) {
    let result = null;
    await Api[storeTypes.GET_SESSION](id)
      .then(({ data }) => {
        state.commit(storeTypes.SET_SESSION_TO_CREATE, data);
        result = data;
      })
      .catch((err) => {
        notify({
          title:
            err.response && err.response.data && err.response.data?.message
              ? err.response.data.message
              : err.message,
          type: "error",
        });
      });
    return result;
  },
  async [storeTypes.GET_REQUESTS](state) {
    let result = null;
    const workplaceId = localStorage.getItem(commonTypes.WORKPLACE_ID);

    if (workplaceId) {
      await Api[storeTypes.GET_REQUESTS](workplaceId)
        .then(({ data }) => {
          state.commit(storeTypes.SET_REQUESTS, data);
          result = data;
        })
        .catch((err) => {
          notify({
            title:
              err.response && err.response.data && err.response.data?.message
                ? err.response.data.message
                : err.message,
            type: "error",
          });
        });
    }

    return result;
  },
  [storeTypes.START_GETTINGS_REQUESTS_TIMER](state) {
    setInterval(() => {
      state.dispatch(storeTypes.GET_REQUESTS);
      if (state.state.user) {
        // проверка на запрос который направили валету из лк
        if (
          // нет процесса либо есть но не важный
          !state.state.currentProcess ||
          (state.state.currentProcess &&
            ![
              processTypes.RECEIVING_A_CAR,
              processTypes.PARKING_THE_CAR,
              processTypes.CAR_DELIVERY,
              processTypes.CAR_READY_TO_ISSUANCE,
              processTypes.CAR_IS_ISSUED,
              processTypes.FORCE_ACCEPT_REQUEST,
            ].includes(state.state.currentProcess))
        ) {
          setActiveRequest({
            requests: state.state.requests,
            user: state.state.user,
            store: state,
          });
        }
      }
    }, 10000);
  },
  async [storeTypes.ACCEPT_REQUEST](state, data: object) {
    // const formData = new FormData();

    await Api[storeTypes.ACCEPT_REQUEST](data)
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .then(() => {
        return Promise.resolve();
      })
      .catch((err) => {
        notify({
          title:
            err.response && err.response.data && err.response.data?.message
              ? err.response.data.message
              : err.message,
          type: "error",
        });
        return Promise.reject();
      });
  },
  async [storeTypes.FINISH_REQUEST](state, data: object) {
    // const formData = new FormData();

    await Api[storeTypes.FINISH_REQUEST](data)
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .then(() => {})
      .catch((err) => {
        notify({
          title:
            err.response && err.response.data && err.response.data?.message
              ? err.response.data.message
              : err.message,
          type: "error",
        });
      });
  },
  [storeTypes.UPLOAD_AVATAR](state, data) {
    return Api[storeTypes.UPLOAD_AVATAR](data).catch((err) => {
      notify({
        title:
          err.response && err.response.data && err.response.data?.message
            ? err.response.data.message
            : err.message,
        type: "error",
      });
    });
  },
  [storeTypes.LOGOUT](state) {
    deleteTokens();
    router.push({ name: "auth" });
    state.commit(storeTypes.FINISH_SHIFT);
    state.commit(storeTypes.SET_PROFILE, null);
    state.commit(storeTypes.SET_PARKING, []);
    state.commit(storeTypes.SET_WORKING_PLACE, null);
  },
};
