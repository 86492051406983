import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import ScreenAuth from "@/components/screens/ScreenAuth/ScreenAuth.vue";
import HomeView from "@/views/HomeView.vue";
import store from "@/store";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "auth",
    component: ScreenAuth,
  },
  {
    path: "/home",
    name: "home",
    component: HomeView,
    beforeEnter: (to, from, next) => {
      const user = store.state.user;
      if (!user) next({ name: "auth" });
      else next();
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
