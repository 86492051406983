// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import imageCompression from "browser-image-compression";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export async function handleImageCompress(file) {
  const imageFile = file;
  console.log("originalFile instanceof Blob", imageFile instanceof Blob); // true
  console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

  const options = {
    maxSizeMB: 0.3,
    maxWidthOrHeight: 1400,
    useWebWorker: true,
    fileType: "image/jpeg",
    initialQuality: 0.8,
  };
  try {
    const compressedFile = await imageCompression(imageFile, options);
    console.log(
      "compressedFile instanceof Blob",
      compressedFile instanceof Blob
    ); // true
    console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

    return compressedFile;
  } catch (error) {
    console.log(error);
  }
}
