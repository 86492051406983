import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store/";
import Notifications from "@kyvg/vue3-notification";
import VueClickOutsidePlugin from "vue-click-outside-of";

const app = createApp(App);

app
  .use(store)
  .use(router)
  .use(Notifications)
  .use(VueClickOutsidePlugin)
  .mount("#app");
