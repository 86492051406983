import axios from "axios";
import { Api } from "@/api/api";
import { updateTokens } from "@/helpers/helpers";
import { commonTypes } from "@/store/constants";

export const axiosApiInstance = axios.create();
// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem(commonTypes.ACCESS_TOKEN);
    config.headers = {
      Authorization: `Bearer ${accessToken}`,
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    const code = error.response?.data?.code;
    if (
      (error.response.status === 403 && !originalRequest._retry) ||
      code === 102
    ) {
      originalRequest._retry = true;
      const access_token = await refreshAccessToken();
      axios.defaults.headers.common["Authorization"] = "Bearer " + access_token;
      return axiosApiInstance(originalRequest);
    }
    return Promise.reject(error);
  }
);

async function refreshAccessToken() {
  let token = null;
  const accessToken = localStorage.getItem(commonTypes.ACCESS_TOKEN);
  const refreshToken = localStorage.getItem(commonTypes.REFRESH_TOKEN);
  await Api.updateTokens({
    access_token: accessToken,
    refresh_token: refreshToken,
    is_company_user: true,
  }).then((res) => {
    const { access_token, refresh_token } = res.data;
    updateTokens({ access_token, refresh_token });
    token = accessToken;
  });
  return token;
}
