
import items from "./car_brands.json";

export default {
  name: "CarModelPicker",
  data() {
    return {
      search: "",
      items: items,
      expand: true,
      show: false,
    };
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    filteredItems() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (this.expand) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return this.items.filter((i: any) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const re = new RegExp(this.search, "gi");
          return re.test(i.name);
        });
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.items.slice(0, 12);
    },
  },
  methods: {
    hideList() {
      // this.expand = false;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.search = "";
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.show = false;
    },
    expandList() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.expand = true;
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.$refs.search.focus();
      }, 100);
    },
  },
};
