import {
  commonTypes,
  processTypes,
  SessionStates,
  storeTypes,
} from "@/store/constants";
import moment from "moment";
import { Request, State, User } from "@/store/interfaces";
import { Ref } from "vue";
import { ActionContext, Store } from "vuex";

export const scrollTop = () => {
  const app = document.querySelector("#app");
  if (app) {
    app.scrollTop = 0;
  }
};

export const updateTokens = ({
  access_token,
  refresh_token,
}: {
  access_token: string;
  refresh_token: string;
}) => {
  localStorage.setItem(commonTypes.ACCESS_TOKEN, access_token);
  localStorage.setItem(commonTypes.REFRESH_TOKEN, refresh_token);
};

export const deleteTokens = () => {
  // localStorage.removeItem(commonTypes.ACCESS_TOKEN);
  // localStorage.removeItem(commonTypes.REFRESH_TOKEN);
  localStorage.clear();
};

export function getShortName(firstName: string, lastName: string): string {
  return `${firstName[0]}${lastName[0]}`.toUpperCase();
}

export function convertUTCToLocalDate(
  date: string | Date | moment.Moment
): moment.Moment | undefined {
  if (!date) {
    return undefined;
  }
  return moment.utc(date).local();
}

export function convertLocalToUTCDate(
  date: string | Date
): moment.Moment | undefined {
  if (!date) {
    return undefined;
  }
  return moment(date).local().utc();
}
interface Photo {
  type: number;
  img: string;
}

enum PhotoTypes {
  PHOTOS_AT_THE_RECEPTION = 1,
  PHOTOS_FROM_PARKING = 2,
}
export function getSessionCarPhoto(photos: Photo[]) {
  const emptyPhoto =
    "https://images.cars.com/cldstatic/wp-content/uploads/img1820523957-1552665129276.jpg";
  if (photos && photos.length) {
    const photoFromParking = photos.find(
      (i) => i.type === PhotoTypes.PHOTOS_FROM_PARKING
    );

    const photoFromReception = photos.find(
      (i) => i.type === PhotoTypes.PHOTOS_AT_THE_RECEPTION
    );
    return photoFromParking
      ? `${process.env.VUE_APP_PARKPASS_DOMAIN}/api/media${photoFromParking.img}`
      : photoFromReception
      ? `${process.env.VUE_APP_PARKPASS_DOMAIN}/api/media${photoFromReception.img}`
      : emptyPhoto;
  } else {
    return emptyPhoto;
  }
}

export function calculateLeftTime(datetime: string): string {
  const targetDate = convertUTCToLocalDate(datetime);
  if (targetDate) {
    return moment
      .duration(targetDate.local().diff(moment()))
      .asMinutes()
      .toFixed(0);
  }
  return "";
}

export function getLeftTimeText(datetime: string): string {
  const time = calculateLeftTime(datetime);
  if (Number(time) > 0) {
    return `Ожидает подачи через ${time} мин.`;
  }
  return "Время подачи прямо сейчас";
}

export function getAvatar(avatar: string): string {
  return process.env.VUE_APP_PARKPASS_DOMAIN + "/" + avatar;
}

export function setActiveRequest({
  requests,
  user,
  store,
}: {
  requests: Request[];
  user: User;
  store: Store<any> | ActionContext<State, object | null>;
}): boolean {
  let requestWasInstalled = false;
  const inProgressRequestId = requests.find(
    (x) => x.accepted_by === user.id
  )?.id;

  if (inProgressRequestId) {
    const request = requests.find((x) => x.id === Number(inProgressRequestId));

    // проверяем статус сессии и меняем экран
    if (request) {
      if (
        request.valet_session?.state ===
        SessionStates.VALET_SESSION_IN_THE_PROCESS
      ) {
        store.commit(storeTypes.SET_PROCESS, processTypes.CAR_DELIVERY);
      } else if (
        request.valet_session?.state ===
        SessionStates.VALET_SESSION_THE_PERSON_IN_CHARGE
      ) {
        store.commit(storeTypes.SET_PROCESS, processTypes.FORCE_ACCEPT_REQUEST);
      } else if (
        request.valet_session?.state ===
        SessionStates.VALET_SESSION_THE_CAR_IS_WAITING
      ) {
        store.commit(
          storeTypes.SET_PROCESS,
          processTypes.CAR_READY_TO_ISSUANCE
        );
        store.commit(storeTypes.SET_PROCESS, processTypes.CAR_IS_ISSUED);
      }
      requestWasInstalled = true;
      store.commit(storeTypes.SET_REQUEST_IN_PROGRESS, request);
    }
    // Запускаем смену
    store.commit(storeTypes.START_SHIFT);
  }
  return requestWasInstalled;
}

export function isNumeric(str: string) {
  if (typeof str != "string") return false; // we only process strings!
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

const characters =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

export function generateString(length: number) {
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}
