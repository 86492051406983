import axios, { AxiosResponse } from "axios";

import { axiosApiInstance } from "@/api/axiosJwtInstance";
import { storeTypes } from "@/store/constants";

export const Api = {
  [storeTypes.AUTH](data: object): Promise<AxiosResponse> {
    return axios.post(
      `${process.env.VUE_APP_PARKPASS_DOMAIN}/api/v1/owner/login/`,
      data
    );
  },
  [storeTypes.GET_PROFILE](): Promise<AxiosResponse> {
    return axiosApiInstance.get(
      `${process.env.VUE_APP_PARKPASS_DOMAIN}/api/v1/owner/profile/`
    );
  },
  [storeTypes.GET_PARKING](): Promise<AxiosResponse> {
    return axiosApiInstance.get(
      `${process.env.VUE_APP_PARKPASS_DOMAIN}/api/v1/owner/valet-parkings/`
    );
  },
  [storeTypes.CREATE_SESSION](data: object): Promise<AxiosResponse> {
    return axiosApiInstance.post(
      `${process.env.VUE_APP_PARKPASS_DOMAIN}/api/v1/owner/valet-sessions/create`,
      data
    );
  },
  [storeTypes.UPDATE_SESSION](data: object): Promise<AxiosResponse> {
    return axiosApiInstance.post(
      `${process.env.VUE_APP_PARKPASS_DOMAIN}/api/v1/owner/valet-sessions/update`,
      data
    );
  },
  [storeTypes.GET_SESSION](id: string): Promise<AxiosResponse> {
    return axiosApiInstance.get(
      `${process.env.VUE_APP_PARKPASS_DOMAIN}/api/v1/owner/valet-sessions/`,
      { params: { id } }
    );
  },
  [storeTypes.GET_REQUESTS](parkingId: string | null): Promise<AxiosResponse> {
    return axiosApiInstance.get(
      `${process.env.VUE_APP_PARKPASS_DOMAIN}/api/v1/owner/valet-requests/`,
      { params: { parking_id: parkingId } }
    );
  },
  [storeTypes.ACCEPT_REQUEST](data: any): Promise<AxiosResponse> {
    return axiosApiInstance.post(
      `${process.env.VUE_APP_PARKPASS_DOMAIN}/api/v1/owner/valet-requests/accept`,
      data
    );
  },
  [storeTypes.FINISH_REQUEST](data: any): Promise<AxiosResponse> {
    return axiosApiInstance.post(
      `${process.env.VUE_APP_PARKPASS_DOMAIN}/api/v1/owner/valet-requests/finish`,
      data
    );
  },
  [storeTypes.GET_HISTORY_SESSIONS](): Promise<AxiosResponse> {
    return axiosApiInstance.get(
      `${process.env.VUE_APP_PARKPASS_DOMAIN}/api/v1/owner/valet-requests/history`
    );
  },
  [storeTypes.UPLOAD_AVATAR](data: object): Promise<AxiosResponse> {
    return axiosApiInstance.post(
      `${process.env.VUE_APP_PARKPASS_DOMAIN}/api/v1/owner/users/avatar/upload`,
      data
    );
  },
  [storeTypes.DISABLE_TELEGRAM_NOTIFICATIONS](): Promise<AxiosResponse> {
    return axiosApiInstance.get(
      `${process.env.VUE_APP_PARKPASS_DOMAIN}/api/v1/owner/telegram/disable`
    );
  },
  updateTokens(data: object) {
    return axios.post(
      `${process.env.VUE_APP_PARKPASS_DOMAIN}/api/v1/auth/tokens/update/`,
      data
    );
  },
};
