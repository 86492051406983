import { State } from "@/store/interfaces";

export const storage: State = {
  user: null,
  workPlace: null,
  shiftStarted: false,
  parking: [],
  currentProcess: null,
  sessionToCreate: null,
  loading: true,
  requests: [],
  requestInProgress: null,
};
